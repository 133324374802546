<template>
  <div class="pa-0 ma-0 white-wavy-wallpaper" style="width: 100%; height: 100%; overflow-y: auto;">
    <app-bar class="white-wavy-wallpaper" :drawer="drawer" @toggleSideBar="toggle"></app-bar>
    <side-bar
      :items="items"
      :avatar="avatar"
      :drawer="drawer"
      :jobTitle="jobTitle"
      :exactLink="exactLink"
      :highlightTitle="highlightTitle"
      @logoutEvent="logoutDialog = true"
    />
    <div>
      <v-container class="pl-12 ml-12 pt-6">
        <admin-header class="mb-12" v-if="!skipBreadcrumb" :title="headerTitle" :links="links"></admin-header>
        <router-view />
      </v-container>
    </div>
    <v-dialog v-model="logoutDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="subtitle-1"
          >Are you sure you want to leave?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="buttons" :loading="logoutLoading" text @click="onLogout"
            >Yes</v-btn
          >
          <v-btn color="buttons" text @click="logoutDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="end" class="mr-12 mb-12">
    <bz-support email="customersupport@beezsocialerp.com"></bz-support>
    </v-row>
  </div>
</template>

<script>
import BzSupport from "@shared/components/BzSupport";
import AppBar from "../components/AppBar";
import SideBar from "../components/SideBar";
import AdminHeader from "../components/AdminHeader";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { eventBus } from "@/main";

export default {
  components: {
    AppBar,
    SideBar,
    BzSupport,
    AdminHeader
  },

  async created() {

    await this.loadInitialData();

  
    eventBus.$on("addElevationSidebar", () => {
      // console.log("Adding Elevation");
      // this.closeDialog();
      this.addElevation();
    });
  },

  computed: {
    ...mapFields("admin", {
      headerTitle2: "mainHeaderTitle",
      accountModules: "accountModules",
      allAccounts: "allAccounts"
    }),
    ...mapFields("auth", ["currentUser", "accountId", "accounts", "currentAccount"]),
    ...mapFields("beezModules",['beezModules'])
  },
  methods: {
    ...mapActions("auth", {
      logoutUser: "logoutUser",
      getCurrentAccount: "getCurrentAccount"
    }),

    ...mapActions("beezModules", {
      getBeeZModules: "getBeeZModules"
    }),

    ...mapActions("admin", {
      getAllAccounts: "getAllAccounts",
      fetchFilesUploaded: "fetchFilesUploaded",
      getAccountModules: "getAccountModules"
    }),
    ...mapActions("modules", {
      fetchAccountModule: "fetchAccountModule"
    }),

    async loadInitialData() {
      await Promise.all([this.getBeeZModules(), this.getAccountModules(this.accountId)]);
    },  

    toggle() {
      this.drawer = !this.drawer;
    },
    async onLogout() {
      this.logoutLoading = true;
      try {
        await this.logoutUser();
        this.logoutDialog = false;
        this.logoutLoading = false;
      } finally {
        this.$router.replace({
          name: "log-in"
        });
      }
    },
    addElevation() {
      if (this.items[this.items.length - 1].title !== "Elevation Module") {
        this.items.push({
          title: "Elevation Module",
          icon: "mdi-elevation-rise",
          link: "elevation-module",
          type: "item"
        });
      }
    }
  },
  data() {
    return {
      avatar: "",
      jobTitle: "",
      drawer: false,
      links: [],
      headerTitle: "",
      skipBreadcrumb: false,
      highlightTitle: "",
      exactLink: true,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          link: "account-dashboard",
          type: "item"
        },
        {
          title: "Users and Roles",
          icon: "mdi-shield-account",
          link: "account-usersroles",
          type: "item"
        },
        {
          title: "Account Setting",
          icon: "mdi-cog",
          link: "account-settings",
          type: "item"
        },
        {
          type: "divider",
          height: "3rem"
        },
        {
          title: "Modules",
          icon: "mdi-view-module",
          link: "modules",
          type: "item"
        },
        {
          title: "Employee On Boarding",
          icon: "mdi-card-account-mail",
          link: "onboarding-module",
          type: "item"
        },
        {
          title: "Elevation Module",
          icon: "mdi-elevation-rise",
          link: "elevation-module",
          type: "item"
        }
      ],
      logoutDialog: false,
      logoutLoading: false,
      fetchedLogoImage: null,
      accountName: null
    };
  },
  watch: {
    currentAccount: {
      deep: true,
      immediate: true,
      handler(value){
        this.accountName = value.name;
      }
    },
    $route: {
      immediate: true,
      handler(value) {
        if ("skip" in value.meta.breadcrumbs && value.meta.breadcrumbs.skip) {
          this.skipBreadcrumb = true;
        } else {
          this.skipBreadcrumb = false;
          this.links = value.meta.breadcrumbs;
          if (this.links && this.links.length > 0) {
            this.headerTitle = this.links[this.links.length - 1].text;
          } else {
            this.headerTitle = "";
          }
        }

        const path = value.path.split("/");
        if (path.length > 4) {
          this.exactLink = false;
          switch (path[3]) {
            case "on-boarding":
              this.highlightTitle = this.items[5].title;
              break;
            case "elevation":
              if (this.items.length == 7) {
                this.highlightTitle = this.items[6].title;
              }
              break;
          }
        } else {
          this.exactLink = true;
        }
      }
    },
    drawer: {
      handler(value) {}
    }
  }
};
</script>

<style></style>
